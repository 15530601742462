import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {debounce, debounceTime, map} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../environments/environment';
const baseURL = environment.api_url;

const self = this;


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
      private httpClient: HttpClient,
      private spinner: NgxSpinnerService
  ) { }



  getRequest(url): Observable<any> {
    this.spinner.show();
    return this.httpClient.get(baseURL + url).pipe(map((response) => {
      setTimeout(() => this.spinner.hide(), 500)
      return response;
    }));
  }

  postRequest(url,data): Observable<any> {
    this.spinner.show();

    return this.httpClient.post(baseURL + url, data).pipe(map((response) => {
      setTimeout(() => this.spinner.hide(), 500)
      return response;
    }));
  }

  updateRequest(url, data): Observable<any> {
    this.spinner.show();
    return this.httpClient.put(baseURL + url, data).pipe(map((response) => {
      setTimeout(() => this.spinner.hide(), 500)
      return response;
    }));
  }

  deleteRequest(url,data): Observable<any> {
    this.spinner.show();
    const userToken = localStorage.getItem('token');
    let config = {
      headers: {
        'Authorization': 'Bearer ' + userToken,
        'Content-Type': 'application/json'
      }
    };
    const options = {
      headers: new HttpHeaders(config.headers),
      body: data
    };
    return this.httpClient.delete(baseURL + url,options).pipe(map((response) => {
      setTimeout(() => this.spinner.hide(), 500)

      return response;

    }));
  }

  tableRequest(url,data,type): Observable<any> {

    const httpOptions = {
      responseType:'arraybuffer',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
    };

   // this.spinner.show();
    return this.httpClient.post(baseURL + url, data, {
      headers:httpOptions.headers,
      responseType: type ? 'arraybuffer' : null
    }).pipe(map((response) => {
      setTimeout(() => this.spinner.hide(), 500);
      return response;
    }));
  }

  postRequestFile(api_url, data, responseType = 0) {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      const configForFile={
        headers:{
          'Authorization' : localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token'),
          // 'Content-Type':  'application/x-www-form-urlencoded'
        }
      };
      let header ;
      if(responseType) {
        header = {headers:configForFile.headers, responseType:'arraybuffer'}
      } else{
        header = {headers:configForFile.headers}
      }

      this.httpClient.post(baseURL + api_url, data,header).subscribe((data):any => {
        this.spinner.hide();
        resolve(data);
      }, err => {
        this.spinner.hide();
        reject(err.error.error);
      });
    });
  }
}
