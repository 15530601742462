import { Injectable } from '@angular/core';
// import RingCentralClient from "ringcentral-client";
import { SDK } from "@ringcentral/sdk";
import {Observable} from 'rxjs';
import Swal from "sweetalert2";
import {ApiService} from './api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '@app-shared/services/storage.service';
// import {RingCentralCall} from 'ringcentral-call/lib';
// import WebPhone from 'ringcentral-web-phone';
// import Subscriptions from '@ringcentral/subscriptions/lib/Subscriptions';
@Injectable({
  providedIn: 'root'
})


export class RingCentralService {
  constructor(public apiService: ApiService,
              public router: Router,
              public storageService : StorageService
  ) {

  }
  call(){
    /*    const sdk = new SDK({
          server: 'https://platform.devtest.ringcentral.com', // Optional, default is production server
          clientId: 'ZPqK4Y11TBCTyDJPs5fgsA',
          clientSecret: 'atU2VBsSTfC0V0ABzaZeMAc9Jt_a76RSKjL0sbdVixyg'
        });
        var platform = sdk.platform();
        platform.login({  "username": '+14242908062',
          "extension": '101',
          "password": 'Ringcentral123'})

        platform.on(platform.events.loginSuccess, function(response) {
          call_ringout()
        })

        async function call_ringout() {
          try {
            var resp = await platform.post('/restapi/v1.0/account/~/extension/~/ring-out', {
              'from': { 'phoneNumber': '+14242908062' },
              'to': { 'phoneNumber': '+16192024841' },
              'playPrompt': true
            })
            var jsonObj = await resp.json()
            //console.log("Call placed. Call status: " + jsonObj.status.callStatus)
          } catch (e) {
            //console.log(e.message)
          }
        }*/


    /*     const sdk = new SDK({
           server: 'https://platform.devtest.ringcentral.com', // Optional, default is production server
           clientId: 'ZPqK4Y11TBCTyDJPs5fgsA',
           clientSecret: 'atU2VBsSTfC0V0ABzaZeMAc9Jt_a76RSKjL0sbdVixyg'
         });
         const client = new RingCentralClient(sdk);


         sdk.platform().login({
           "username": '+14242908062',
           "extension": '101',
           "password": 'Ringcentral123'
         }).then(() => {
           //console.log("Login success");

           client.account().extension().ringOut().post({
             from: { phoneNumber: "+14242908062" },
             to: { phoneNumber: "+16192024841" },
             playPrompt: false
           }).then(ringout => {
             //console.log("Ringout sucess", ringout);
             // To check the call status: `client.account().extension().ringout(ringout.id).get();`
           }, e => {
             console.error("Fail to ringout", e);
           });

           return client.account().get(); // Call RingCentral REST API
         }).then((accountInfo) => {
           //console.log("Current account info", accountInfo);
           return sdk.platform().logout();	// Logout
         }).then(() => {
           //console.log("logout success");
         }).catch(e => {
           console.error("Error occured", e);
         });*/
  }


  /*  webRTC(){
      var rcCall;

      var sdk = new SDK({
        clientId: 'ZPqK4Y11TBCTyDJPs5fgsA',
        // clientId: 'oPpcRzhZRrWV9ymmhssrRw',
        clientSecret: 'atU2VBsSTfC0V0ABzaZeMAc9Jt_a76RSKjL0sbdVixyg',
        // clientSecret: '9LY05_dwRdS0PMgeZMAgDAfII_99ZUSzKY05xXMv5ZNg',
        appName: 'WebApp',
        appVersion: '0.0.1',
        server: SDK.server.sandbox // or .sandbox
      });
      var subscriptions = new Subscriptions({ sdk });

      var platform = sdk.platform();

      platform
          .login({
            username: '+14242908062',
            password: 'Ringcentral123'
          })
          .then(function() {
            return platform
                .post('/restapi/v1.0/client-info/sip-provision', {
                  sipInfo: [{transport: 'WSS'}]
                })
          })
          .then(function(res) {
            //console.log(res.json());
            return res.json();
          })
          .then(function(sipProvision) {
            // create RingCentral web phone instance
            var rcWebPhone = new WebPhone(sipProvision, {
              appKey: "ZPqK4Y11TBCTyDJPs5fgsA",
              // appKey: "oPpcRzhZRrWV9ymmhssrRw",
              appName: 'RingCentral Call Demo',
              appVersion: '0.0.1'
            });

            // create RingCentral call instance
            rcCall = new RingCentralCall({ webphone: rcWebPhone, sdk: sdk, subscriptions: subscriptions });
            return rcCall;
          })
    }*/

  setDefaultNumber(phone){
    window.addEventListener('message', (e) => {
      const data = e.data;
      ////console.log(data);
      if (data) {
        switch (data.type) {
          case 'rc-dialer-status-notify':
            // get dialer status from widget
            if(data.ready && phone){
              document.querySelector("#rc-widget-adapter-frame")['contentWindow'].postMessage({
                type: 'rc-adapter-new-call',
                phoneNumber: phone,
                toCall: false,
              }, '*');
            }
            break;
          default:
            break;
        }
      }
    });
  }

  registerService(){

    let callObservable = new Observable(observer => {
      window.addEventListener('message', (e) => {
        const data = e.data;
        if (data) {

          switch (data.type) {
            case 'rc-call-ring-notify':
              // get call when user gets a ringing call
              observer.next({type: 'incoming-call', data: data.call});
              break;
            case 'rc-call-init-notify':
              // get call when user creates a call from dial
              observer.next({type: 'call-created', data: data.call});
              break;
            case 'rc-call-start-notify':
              // get call when a incoming call is accepted or a outbound call is connected
              //console.log(data.type);
              observer.next({type: 'call-start', data: data.call});
              break;
            case 'rc-call-hold-notify':
              // get call when user holds a call
              //console.log('when user holds a call', data.call);
              break;
            case 'rc-call-resume-notify':
              // get call when user unholds call
              //console.log('when user unholds call', data.call);
              break;
            case 'rc-call-end-notify':
              // get call on call end event
              //console.log(data.type);
             observer.next({type: 'call-end', data: data.call});
              break;
            case 'rc-call-mute-notify':
              // get call on call muted or unmuted event
              //console.log(data.call);
              break;
            default:
              break;
          }
        }
      })
    });

    return callObservable;
  }


  handleCallEnd(data){

    if((data.direction == 'Outbound' || data.direction == 'Inbound') && data.callStatus =='webphone-session-connected') {

      if(this.router.url.split('/')[1] == 'member-interaction' && this.router.url.split('/')[2] == 'edit') {
        localStorage.setItem('call_end', '1');
        localStorage.setItem('call_status', data.direction);
      }
    }
  }

  handleCallStart(data, memberId?:string){

    if(data.direction == 'Outbound' && data.callStatus =='webphone-session-connected') {
      this.apiService.postRequest('cce/callstatus/add',{MemberId:memberId ? memberId : null,CallId:data.callId,CallType:data.direction,Phone:data.to})
          .subscribe(res=>{
            if(res.status){
              this.handleIncomingCall(data);
            }
          })
    }

    if(data.direction == 'Inbound' && data.callStatus =='webphone-session-connected') {
      this.apiService.postRequest('cce/callstatus/add',{MemberId:memberId ? memberId : null,CallId:data.callId,CallType:data.direction,Phone:data.from.slice(-10)})
          .subscribe(res=>{
            if(res.status){
            }

            this.handleIncomingCall(data);
          })
    }
  }

  handleIncomingCall(data) {
    if (data.direction == 'Inbound' && data.callStatus) {
      //console.log("INCOMING=========", data)
      //  cce/memberdetails
      /* address: "301 W Reno Ave, Oklahoma City, OK 73102, United States"
       alternatePhone: null
       dob: "Sep 10 1996 12:00AM"
       email: "Billy@yopmail.com"
       gender: "Female"
       memberId: "4EB991D6-B16D-43B9-9AB9-AB482924717A"
       name: "Billy Jordan"
       phone: "8779322100"
       zoneName: "Zone1"*/
      this.apiService.postRequest('cce/memberdetails', {phone: data.from.slice(-10)})
          .subscribe(res => {
            if (res.data) {
              Swal.fire({
                title: 'You have a incoming call from ' + res.data.name,
                text: 'Do you want to open up member details ?',
                icon: 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes'
              }).then((result) => {
                if (result.isConfirmed)
                  this.router.navigate([]).then(result => {
                    this.storageService.set('memberId',res.data.memberId);
                    window.open('/member-interaction/edit', '_blank');
                  });

              })
            } else {

              Swal.fire({
                title: 'Details for incoming call not found in the system',
                text: 'Do you want to search member details in our system?',
                icon: 'warning',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes'
              }).then((result) => {
                if (result.isConfirmed)
                {
                  if(!this.router.url.includes('member-interaction/list')) {
                    this.router.navigate([]).then(result => {
                      window.open('/member-interaction/list', '_blank');
                    });
                  }
                }

              })
            }
          })
    }
  }

}
