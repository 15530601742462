
import {Injectable} from '@angular/core';
import {HttpRequest,HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, timeout} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {CommonService} from './common.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(private router: Router,
              private toastr: ToastrService,
              private commonService: CommonService,
              private spinner: NgxSpinnerService
  ) {}
  /**
   * intercept all XHR request
   * @param request
   * @param next
   * @returns {Observable<A>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /**
     * continues request execution
     */

    const userToken = localStorage.getItem('token');

    //pass auth token
    if(userToken) {
      request = request.clone({
        setHeaders: {'Authorization': `Bearer ${userToken}`},
      });
    }
    return next.handle(request).pipe(timeout(30000),catchError((error, caught) => {
      this.handleAuthError(error);
      return of(error);
    }) as any);
  }


  /**
   * manage-patients errors
   * @param err
   * @returns {any}
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {

    if (err.status === 401) {
      this.toastr.error('Unauthorized! Please login','Error!',{timeOut : 5000});
      this.commonService.signOut();
      return of(err.message);
    }

    if([400,500].indexOf(err.status) != -1){
      this.spinner.hide();
      this.toastr.error(err.error.message, 'Error!');
    }

    if(err.status == 0){
      this.spinner.hide();
      this.toastr.error('Network Issue Or Some Error Occured','Error!',{timeOut : 5000});
      return of('Some Error Occured');
    }
    throw err;
  }
}
