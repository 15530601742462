import { Injectable, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from '@angular/router';
import Swal from "sweetalert2";
import {environment} from '../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {StorageService} from './storage.service';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  notifyCount: EventEmitter<number> = new EventEmitter();
  notifyUpdate: EventEmitter<any> = new EventEmitter();
  changeSideBar= new Subject<any>();
  changeHeaderBar= new Subject<any>();
  providerInfo = new BehaviorSubject<any>(null);

  emailPattern = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
  passwordPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d.*)(?=.*\\W.*)[a-zA-Z0-9\\S]{8,}$';
  firstNamePattern = '^[a-zA-Z ,.\'-]*$';
  numberPattern = '^[1-9]+[0-9]*$';
  nameWithSpacePattern = /^[a-zA-Z ,.'-]*$/i;
  addressPattern = '^[A-Za-z0-9\'\\.\\-\\s\\,]*$';
  IcdCodePattern = '^([a-tA-T]|[v-zV-Z])\\d[a-zA-Z0-9]((\\.|s-)[a-zA-Z0-9]{1,4})?$';
  // IcdCodePattern = '^([a-tA-T]|[v-zV-Z])\\d[a-zA-Z0-9](\\.[a-zA-Z0-9]{1,4})?$';
  phonePattern = '^\\d{3}-\\d{3}-\\d{4}$';
  faxPattern = '^(\\+?\\d{1,}(\\s?|\\-?)\\d*(\\s?|\\-?)\\(?\\d{2,}\\)?(\\s?|\\-?)\\d{3,}\\s?\\d{3,})$';
  feePattern = '^(?!0+(\\.0+)?$)\\d{0,5}(.\\d{1,2})?$';
  feePercentPattern = '^(?:[1-9][0-9]{0,3}(?:\\.\\d{1,2})?|999|999.00)$';
  // timePattern = '(1[012]|[1-9]):[0-5][0-9](\\s)?(?i)(am|pm)';
  alphaOrNumericOrBoth = '^([A-Za-z]|[0-9])+$';
  insuranceNumber = '^[a-zA-Z0-9 -]*$';
  quantity = '^(?:[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9])$';

  // DataTable Pagination Icon
  nextPage = 'Next <i class="fa fa-lg fa-angle-right" aria-hidden="true"></i>';
  previousPage = '<i class="fa fa-lg fa-angle-left" aria-hidden="true"></i> Previous';
  firstPage = '<i class="fa fa-lg fa-angle-double-left" aria-hidden="true"></i>';
  lastPage = '<i class="fa fa-lg fa-angle-double-right" aria-hidden="true"></i>';
  user = this.storageService.get('user');

  //shared data
  shared_data : any;
  constructor(
      public router: Router,
      private storageService: StorageService,
      private apiService: ApiService,
      private toastr: ToastrService
  ) {
  }

  emitNavChangeEvent(number) {
    this.notifyCount.emit(number);
  }

  emitUpdateNotifyEvent(item) {
    this.notifyUpdate.emit(item);
  }

  /*Used to update sidebar */
  passToSideBar(data){ //type : string , data: any
    this.changeSideBar.next(data);
  }

  /*Used to update header */
  passToHeaderBar(data){ //type : string , data: any
    this.changeHeaderBar.next(data);
  }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
    }
  }

  public removeScope(type) {
    return window.localStorage.removeItem(type);
  }

  getShortName(name){
    if(name)
      return name.split(' ').map(n => n[0]).join('');
  }

  addMinutes = function(date ,minutes) {
    var copiedDate = new Date(date);
    return new Date(copiedDate.getTime() + minutes * 60000);
  }

  addDay = function(date,days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public noWhitespaceValidator(control: FormControl) {

    let isWhitespace = (control.value || '').trim().length === 0;
    //////console.log(isWhitespace);
    let isValid = !isWhitespace;
    return isValid ? null : {'required': true}
  }

  signOut(){
    if(localStorage.getItem('token') == null){
      this.storageService.clear();
      this.router.navigate(['/login']).then(()=> window.location.reload());
    }

    this.apiService.getRequest('user/logout').subscribe(res =>{
      if(res.status){
         if(document.getElementById('rc-script'))
         {
          window['RCAdapter'].dispose();
          document.getElementById('rc-script').remove();
         }
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('token');
        localStorage.removeItem('call_end');
        this.storageService.clear();
        setTimeout(()=>{
          this.router.navigate(['/login']).then(()=> window.location.reload());
        });
      }
    });
  }

  showAlert(title,msg,btnTxt,icon){
    return  Swal.fire({
      title: title,
      text: msg,
      icon: icon,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#0d8ccd',
      cancelButtonColor: '#d33',
      confirmButtonText: btnTxt,
      cancelButtonText: 'No'
    })
  }

  downloadDoc(item){
    if(item) {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = environment.api_url + '' + item.documentPath;
      a.download = item.downloadName;
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    }
  }

  getPermissions(moduleName){
    return new Promise((resolve, reject) => {
      let user = this.storageService.get('user');
      let module;
      if (user.role == 'Admin')
        resolve(true);
      else {
        let  ModuleSpecificationId = user.role == 'Technician' || user.role == 'Staff' ? 2: 1;
        this.apiService.postRequest('admin/permission/get',{UserId:user.userId, Role:user.role, ModuleSpecificationId: ModuleSpecificationId}).subscribe(res => {
          if (res['data'].length > 0) {
            let modules = res['data'];
            let permission = [];
            module = modules.filter(item => item.slug === moduleName[0]);
            if(moduleName[0] === 'project_zones')
              module = modules.filter(mod => mod.slug === 'zone_map');
           /* //console.log(module);
            var result = modules.filter(function (o1) {
              //console.log('o1',o1);
              return moduleName.some(function (o2) {
                //console.log(o2);
                return o1.moduleName === o2; // return the ones with equal id
              });
            });
            //console.log(result);*/
            resolve(module);

          }else{
            this.toastr.error('No Permissions set for this user. Kindly contact System Admin');
            this.router.navigate(['manage-profile'])
          }
        });
      }
    });
  }

   b64toBlob = (b64Data, contentType='', sliceSize=512) => {

     return new Promise(resolve => {
       try {
         const byteCharacters = atob(b64Data);
         const byteArrays = [];

         for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
           const slice = byteCharacters.slice(offset, offset + sliceSize);

           const byteNumbers = new Array(slice.length);
           for (let i = 0; i < slice.length; i++) {
             byteNumbers[i] = slice.charCodeAt(i);
           }

           const byteArray = new Uint8Array(byteNumbers);
           byteArrays.push(byteArray);
         }

         const blob = new Blob(byteArrays, {type: contentType});
         resolve(blob);
       }catch (e) {
         resolve(false);
       }
     });
  }

  findInvalidControls(f: FormGroup) {
    const invalid = [];
    const controls = f.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
        //console.log(controls[name]);
      }
    }
    return invalid;
  }


  /**
   * Get a center latitude,longitude from an array of like geopoints
   *
   * @param array data 2 dimensional array of latitudes and longitudes
   * For Example:
   * $data = array
   * (
   *   0 = > array(45.849382, 76.322333),
   *   1 = > array(45.843543, 75.324143),
   *   2 = > array(45.765744, 76.543223),
   *   3 = > array(45.784234, 74.542335)
   * );
   */
  getCenterFromDegrees(data)
  {
    if (!(data.length > 0)){
      return false;
    }

    var num_coords = data.length;

    var X = 0.0;
    var Y = 0.0;
    var Z = 0.0;

    for(var i = 0; i < data.length; i++){
      var lat = data[i].lat * Math.PI / 180;
      var lon = data[i].long * Math.PI / 180;

      var a = Math.cos(lat) * Math.cos(lon);
      var b = Math.cos(lat) * Math.sin(lon);
      var c = Math.sin(lat);

      X += a;
      Y += b;
      Z += c;
    }

    X /= num_coords;
    Y /= num_coords;
    Z /= num_coords;

    var lon = Math.atan2(Y, X);
    var hyp = Math.sqrt(X * X + Y * Y);
    var lat = Math.atan2(Z, hyp);

    var newX = (lat * 180 / Math.PI);
    var newY = (lon * 180 / Math.PI);

    return [newX, newY];
  }


}
