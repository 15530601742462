export const environment = {
  production: false,
  // api_url: "http://3.7.81.188:5003/api/",
  //image_url: "http://3.7.81.188:5003/",
  /*api_url: "https://apistarpoint.ospbiz.com/api/",
  image_url: "https://apistarpoint.ospbiz.com",
  websocket_url: "https://apistarpoint.ospbiz.com",
  mapApiKey: 'AIzaSyB-pYWCXIU-jSFbboUdDoxZauSO08p3Eco',
  build : "dev"*/
  api_url: "https://api.starpointapp.com/api/",
  image_url: "https://api.starpointapp.com/",
  websocket_url: "https://api.starpointapp.com",
  mapApiKey: 'AIzaSyB-pYWCXIU-jSFbboUdDoxZauSO08p3Eco',
  build : "UAT"
};
