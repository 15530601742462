import {Injectable, ViewChild} from '@angular/core';
import * as signalR from '@aspnet/signalr';
import {HubConnection} from '@aspnet/signalr';
import {CommonService} from './common.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from './storage.service';
import Swal from "sweetalert2";
import {ApiService} from '@app-shared/services/api.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: HubConnection;
  user  = this.storageService.get('user');
  modalRef: NgbModalRef;
  constructor(public router: Router,
               private commonService: CommonService,
               private storageService: StorageService,
               private apiService: ApiService,
               private toastr: ToastrService,
               private modalService: NgbModal) {

  }

  public startConnection = () => {
    this.hubConnection  = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Debug)
        .withUrl(environment.websocket_url+"/notificationHub", {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .build();

    this.hubConnection.start().then(() => {
      ////console.log("signalR started");

    }).catch(err => {
      ////console.log(err);
    });

    this.hubConnection.on('error' ,function(err){
      ////console.log(err);
    })

  };



  // TECHNICIAN LEAVE APPLY
  public techicianLeaveApply(){
    this.hubConnection.on("OnTechnicianApplyForLeave", (title: string, body: string, type: string, extraDataJson: string) => {
      // {"VisitId":null,"Title":"Technician applied for leave.","FromUserId":"AC811938-E662-48FD-A145-E6BA943E1516","ToUserId":"491FC5A7-9313-44E0-B3B1-7EE9B403F163","Reason":null,"Message":"Rahu Lodhi technician takes a leaves on 03/15/2021 - 03/19/2021 Reason : Out of station","Type":"Technician-Leave","NotificationId":21,"ProfileImage":"\\ContentFiles\\default-profile-admin.jpeg"}
      //Technician applied for leave. Rahu Lodhi technician takes a leaves on 03/15/2021 - 03/19/2021 Reason : Out of station Technician-Leave
      this.showNotification(extraDataJson, title, body, type);
       let count = parseInt(localStorage.getItem('notificationCount'));
      this.commonService.emitNavChangeEvent(count? count :1)
    });
  }


  showNotification(extraDataJson,title, body, type){ //extraJSON - visit details, type - notification type , message - custom title , notifyType - success/error

    if(type == 'Technician-Leave'){
      Swal.fire({
        title: title,
        text: body,
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Reject',
        confirmButtonText: 'Accept'
      }).then((result) => {

        // /availability/approveLeave
        this.apiService.updateRequest('availability/approveLeave',{availabilityLeaveId:extraDataJson.availabilityLeaveId,IsApproved:result.isConfirmed ? 1: 0}).subscribe(res=>{
          if(res.status){
            this.toastr.success(res.message);
          }
          if(!res.status){
            this.toastr.error(res.message);
          }
        })
      })
    }


  }

  closeConnection(){
    this.hubConnection.stop();
  }
}
