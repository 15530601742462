import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard, LoginGuard} from './shared/guard';

const routes: Routes = [
  {path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
  {path: 'login', loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule) , canActivate: [LoginGuard]},
  {path: 'forgot-password', loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) , canActivate: [LoginGuard]},
  {path: 'reset-password/:Email/:EmailToken', loadChildren: () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule) , canActivate: [LoginGuard]},
  {path: 'change-password', loadChildren: () => import('./auth/change-password/change-password.module').then(m => m.ChangePasswordModule), canActivate: [AuthGuard]},
  { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule) },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
