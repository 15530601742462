import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CommonService,StorageService} from '../services';
import {SidebarMenus} from '@app-shared/models/sidebar';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private commonService: CommonService,
              private toastr : ToastrService,
              private storageService: StorageService) {}

  canActivate() {
    if( localStorage.getItem('isLoggedIn') ) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let user = this.storageService.get('user');
    return this.commonService.getPermissions([route.data.slug]).then((res: any) => {
      if (user.role == 'Admin')
        return true;
      else {
        if(res.length > 0) {
          switch (route.data.slug) {
            case 'dashboard':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              break;

            case 'manage_users':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'manage_roles':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'manage_access':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;


            case 'manage_members':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'project_zones':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'scheduled_project':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;


            case 'MANAGE_ZONES':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else  if (route.data.page === 'map')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              break;

            case 'manage_forms':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'add')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'manage_tests':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              break;

            case 'manage_projects':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'cost_reporting':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

              case 'test_reports':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'manage_appointments':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              break;

            case 'day_sheet_entries':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

              case 'electronic_claims':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

              case 'claim_status_history':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;


            case 'technicians_schedule':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'technician_payroll':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'member_interactions_and_appointments':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;


            case 'billing_provider':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'provider':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'offices':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'referring_provider':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'cpt_codes':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'icd_codes':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;
            case 'facility':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

            case 'insurance_plan':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;

              case 'insurancemaster':
              if (route.data.page === 'list')
                return res[0].isView ? res[0].isView : this.router.navigate(['dashboard']);
              else if (route.data.page === 'edit')
                return res[0].isEdit ? res[0].isEdit : this.router.navigate(['dashboard']);
              break;
          }
        }else{
          this.toastr.error('No Permissions set for this user. Kindly contact System Admin');
          this.router.navigate(['manage-profile'])
        }
      }
    });
  }
}
