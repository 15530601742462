import { Component } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SignalRService} from './shared/services';
const version = require('../../package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public router: Router,
  private signalRService : SignalRService
  ) {
    //console.log(this.isNewerVersion(localStorage.getItem('version'), version.version));
    if(this.isNewerVersion(localStorage.getItem('version'), version.version)){
      localStorage.clear();
      localStorage.setItem('version', version.version);
      window.location.reload()
    }
  }


  ngOnInit() {
    this.signalRService.startConnection();
  }


  isNewerVersion (oldVer: any, newVer: any) {
    const oldParts = oldVer ? oldVer.split('.') : '0.0.0'
    const newParts = newVer.split('.')
    for (var i = 0; i < newParts.length; i++) {
      const a = ~~newParts[i] // parse int
      const b = ~~oldParts[i] // parse int
      if (a > b) return true
      if (a < b) return false
    }
    return false
  }
}
